@import '../../_globalColor';

#topButton {
	visibility: hidden;
	position: fixed;
	bottom: 20px;
	right: 30px;
	z-index: 99;
	border: none;
	outline: none;
	background-color: $buttonColor;
	color: white;
	cursor: pointer;
	padding: 15px;
	border-radius: 15px;
	font-size: 25px;
}

#topButton:hover {
	background-color: $topButtonHover;
	transition: all ease-in-out 0.2s;
}

@media (max-width: 480px)and (min-width: 320px) {
	#topButton {
		visibility: hidden;
		position: fixed;
		bottom: 60px;
		right: 10px;
		z-index: 99;
		border: none;
		outline: none;
		background-color: $buttonColor;
		color: white;
		cursor: pointer;
		padding: 15px;
		border-radius: 15px;
		font-size: 25px;
	}
}
@import '../../_globalColor';

.social-media-div {
	font-size: 2em;
}

.icon-button {
	// margin-bottom: 10px;
}

.icon-button i {
	color: $textColorDark;
	border-radius: 2.6rem;
	cursor: pointer;
	display: inline-block;
	font-size: 1.3rem;
	height: 2.6rem;
	line-height: 2.6rem;
	margin: 0 5px;
	position: relative;
	text-align: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 2.6rem;
	margin-bottom: 10px;
	transition: 0.2s ease-in;
}
.icon-button span {
	// padding-right: 15px ;
	// color: $textColorDark;
	border-radius: 1rem;
	cursor: pointer;
	display: inline-block;
	// font-size: 1.3rem;
	height: 2.6rem;
	line-height: 2.6rem;
	margin: 0 10px;
	position: relative;
	text-align: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	// width: 2.6rem;
	margin-bottom: 10px;
	transition: 0.2s ease-in;
}

.facebook i {
	background-color: $faceBook;
}

.linkedin span {
	// background-color: $linkedin;
	color: $linkedin
}

.github span {
	// background-color: $github;
	color: $github;
}

.gitlab i {
	background-color: $gitlab;
}

.google i {
	background-color: $google;
}

.twitter i {
	background-color: $twitter;
}

.medium i {
	background-color: $medium;
}

.stack-overflow i {
	background-color: $stackoverflow;
}

.kaggle i {
	background-color: $kaggle;
}

.instagram i {
	background-color: $instagram;
}

/* Hover Transition */
.twitter i:hover,
.google i:hover,
.gitlab i:hover,
.github i:hover,
.linkedin i:hover,
.facebook i:hover,
.instagram i:hover,
.stack-overflow i:hover,
.kaggle i:hover,
.medium i:hover {
	background-color: $textColor;
}

/* Media Query */
@media (max-width: 768px) {
	.social-media-div {
		text-align: center;
	}
}
